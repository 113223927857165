import React, { useState } from "react";
import PropTypes from "prop-types";
import { Carousel, CarouselItem, CarouselControl } from "reactstrap";
import "./_storesCarousel.scss";

const StoresCarousel = ({ storeImages }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === storeImages.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? storeImages.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const slides = storeImages.map((imageData) => {
    if (activeIndex >= storeImages.length) {
      setActiveIndex(0);
    }

    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={imageData.imageTitle}
      >
        <picture>
          <source
            type="image/webp"
            srcSet={imageData.webp}
            className="stores-image"
          />
          <img
            src={imageData.image}
            alt={imageData.imageTitle}
            className="stores-image"
          />
        </picture>
      </CarouselItem>
    );
  });

  const justOneImage = storeImages.map((imageData) => {
    if (activeIndex >= storeImages.length) {
      setActiveIndex(0);
    }

    return (
      <picture>
        <source
          type="image/webp"
          srcSet={imageData.webp}
          className="stores-image"
        />
        <img
          src={imageData.image}
          alt={imageData.imageTitle}
          className="stores-image"
        />
      </picture>
    );
  });

  return (
    <div className="menu-carousel-container">
      {storeImages.length === 1 ? (
        <div>{justOneImage}</div>
      ) : (
        <Carousel activeIndex={activeIndex} next={next} previous={previous}>
          {slides}
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={next}
          />
        </Carousel>
      )}
    </div>
  );
};

StoresCarousel.propTypes = {
  storeImages: PropTypes.arrayOf(
    PropTypes.shape({
      imageTitle: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      webp: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default StoresCarousel;
