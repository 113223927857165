import React from "react";
import PropTypes from "prop-types";
import "./_grid.scss";

const Grid = ({ children }) => {
  return <div className="grid-layout">{children}</div>;
};

Grid.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Grid;
