import React from "react";
import PropTypes from "prop-types";
import "./_storesNavItem.scss";

import itemArrow from "../../../../assets/Icons/stores/storesNavBar/item-arrow.png";

const StoresNavItem = ({ storeName, handleStoreClick, isSelected = false }) => {
  const handleClick = (event) => {
    const choosedStore = event.currentTarget.textContent;

    handleStoreClick(choosedStore);
  };

  return (
    <li className="stores-nav-item">
      <button type="button" onClick={handleClick}>
        <span className="store-name">{storeName}</span>
        {isSelected ? (
          <div className="arrow-container">
            <img className="item-arrow" src={itemArrow} alt="Seta" />
          </div>
        ) : (
          ""
        )}
      </button>
    </li>
  );
};

StoresNavItem.propTypes = {
  storeName: PropTypes.string.isRequired,
  handleStoreClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
};

StoresNavItem.defaultProps = {
  isSelected: false,
};

export default StoresNavItem;
