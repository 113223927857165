import React from "react";
import PropTypes from "prop-types";
import StoresNavItem from "./storesNavItem/StoresNavItem";

import "./_storesNavBar.scss";

const StoresNavBar = ({ data, handleStoreClick, selectedStore }) => {
  return (
    <nav className="stores-nav-bar">
      <ul>
        {data.map(({ storeName }) => {
          return (
            <StoresNavItem
              handleStoreClick={handleStoreClick}
              key={storeName}
              storeName={storeName}
              isSelected={selectedStore === storeName}
            />
          );
        })}
      </ul>
    </nav>
  );
};

StoresNavBar.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      storeName: PropTypes.string.isRequired,
      storeImages: PropTypes.arrayOf(
        PropTypes.shape({
          imageTitle: PropTypes.string.isRequired,
          image: PropTypes.string.isRequired,
        })
      ),
      storeAdress: PropTypes.string.isRequired,
      openingHours: PropTypes.string.isRequired,
      contactNumber: PropTypes.string.isRequired,
      googleMapAdress: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleStoreClick: PropTypes.func.isRequired,
  selectedStore: PropTypes.string.isRequired,
};

export default StoresNavBar;
