import React from "react";

import "./_home.scss";

import homeData from "./data";

const Home = () => {
  const { logo, text, photo, logoWebp, textWebp, photoWebp } = homeData;

  return (
    <>
      <div className="home-screen">
        <div className="icon-line">
          {/* <img src={logo} alt="Icone de coração açaí aju" className="icon" /> */}
          <picture>
            <source type="image/webp" srcSet={logoWebp} className="icon" />
            <img src={logo} alt="Icone de coração açaí aju" className="icon" />
          </picture>
        </div>
        <div className="middle">
          <div className="home-title">
            {/* <img src={text} alt="Gente que ama açaí" className="title" /> */}
            <picture>
              <source type="image/webp" srcSet={textWebp} className="title" />
              <img src={text} alt="Gente que ama açaí" className="title" />
            </picture>
          </div>

          <div className="acai">
            {/* <img src={photo} alt="Pote com açaí" /> */}
            <picture>
              <source type="image/webp" srcSet={photoWebp} />
              <img src={photo} alt="Pote com açaí" />
            </picture>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
