import React from "react";
import woman from "../../assets/images/about.png";
import Img from "../../assets/images/wave.svg";
import "./_about.scss";

const About = () => {
  return (
    <>
      <div className="about-screen">
        <div className="image">
          <img src={woman} className="main-image" alt="" />
          <img src={Img} className="wave-image" alt="" />
        </div>
        <div className="about-text">
          <p>
            Uma década de muito amor e açaí. Isso mesmo.... uma década. Em 2010
            Mirian Ferreira, movida por uma certeza instintiva, teve a brilhante
            e frutífera ideia de se lançar no mundo empresarial, abrindo sua
            primeira loja de açaí na orla de Atalaia no dia 26 de agosto do
            mesmo ano. Um sucesso absoluto que à mesma não cansa de repetir que
            foi um presente de Deus. A partir desse instante, o Grupo Açaí Aju
            começa sua trajetória de sucesso e tradição como o melhor açaí da
            região, quiçá, do mundo. Atualmente, a empresária já multiplica seu
            sucesso pelos quatro cantos da Europa, com o melhor açaí do
            planeta... Amazing!! Açaí Aju, de Aracaju para o mundo.
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
