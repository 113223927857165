import React from "react";

import "./_adressModal.scss";

import { Modal, ModalBody } from "reactstrap";

const adressModal = ({ isOpen, onClose, data }) => {
  const handleCloseClick = () => {
    onClose();
  };

  const {
    storeName,
    storeAdress,
    openingHours,
    contactNumber,
    googleMapAdress,
  } = data;

  return (
    <Modal id="adress-modal" isOpen={isOpen} toggle={handleCloseClick}>
      <ModalBody>
        <h3 className="modal-store-name">{storeName}</h3>
        <div className="information-container">
          <div className="information-item">
            <span className="information-title">Endereço: </span>
            <span className="information-description">{storeAdress}</span>
          </div>
          <div className="information-item">
            <span className="information-title">Horário: </span>
            <span className="information-description">{openingHours}</span>
          </div>
          <div className="information-item">
            <span className="information-title">Telefone: </span>
            <span className="information-description">{contactNumber}</span>
          </div>
        </div>
        <a href={googleMapAdress} target="_blank" rel="noopener noreferrer">
          <div className="map-button">
            <span>MAPA</span>
          </div>
        </a>
      </ModalBody>
    </Modal>
  );
};

export default adressModal;
