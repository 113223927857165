import acai from "../../assets/images/home/acai.png";
import logo from "../../assets/images/home/coracao.png";
import text from "../../assets/images/home/texto.png";
import acaiWebp from "../../assets/images/home/acai.webp";
import logoWebp from "../../assets/images/home/coracao.webp";
import textWebp from "../../assets/images/home/texto.webp";

const homeData = {
  logo,
  text,
  photo: acai,
  logoWebp,
  textWebp,
  photoWebp: acaiWebp,
};

export default homeData;
