import React from "react";
import { Switch, Route } from "react-router-dom";

// Screens
import Home from "./screens/home/Home";
import Stores from "./screens/stores/Stores";
import Menu from "./screens/menu/Menu";
import Reservations from "./screens/reservations/Reservations";
import About from "./screens/about/About";
import Contacts from "./screens/contacts/Contacts";

const Routes = () => {
  return (
    <>
      <Switch>
        <Route exact path="/stores" component={Stores} />
        <Route exact path="/menu" component={Menu} />
        <Route exact path="/reservations" component={Contacts} />
        <Route exact path="/about" component={About} />
        <Route exact path="/contacts" component={Contacts} />
        <Route exact path="/" component={Home} />
      </Switch>
    </>
  );
};

export default Routes;
