import React from "react";

import "./_content.scss";

// Components
import Routes from "../../Routes";

const Content = () => {
  return (
    <div className="content-container">
      <Routes />
    </div>
  );
};

export default Content;
