import React, { useState } from "react";

import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav } from "reactstrap";
import SidebarItem from "./sidebarItem/SidebarItem";

import "./_sidebar.scss";

import sideBarData from "./data";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isBurguer, setIsBurguer] = useState("burguer-btn");

  const toggle = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setIsBurguer("close-btn");
    } else {
      setIsBurguer("burguer-btn");
    }
  };

  const { logoData, menuData } = sideBarData;

  return (
    <div className="sidebar-container">
      <Navbar color="light" light expand="md">
        <NavbarBrand href="/">
          <div className="logo-layer">
            <img
              src={logoData.logoImg}
              alt="Logo Açai Aju"
              className="logo-style"
            />
            <img
              src={logoData.logoText}
              alt="Texto Logo Açai Aju"
              className="d-none d-md-block logo-text-style"
            />
          </div>
        </NavbarBrand>
        <div className="hamburger-menu">
          <div>Menu</div>
          <NavbarToggler onClick={toggle} className={`${isBurguer}`} />
        </div>
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="d-none d-md-block"
            >
              <img
                src={menuData.littleWave}
                alt="Ondinha"
                style={{ maxWidth: "58.8px", maxHeight: "7.79px" }}
              />
            </div>

            {menuData.linkData.map(({ id, link, Icon, imgAlt, text }) => {
              return (
                <SidebarItem
                  key={id}
                  id={id}
                  link={link}
                  Icon={Icon}
                  imgAlt={imgAlt}
                  text={text}
                />
              );
            })}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="d-none d-md-block"
            >
              <img
                src={menuData.littleWave}
                alt="Ondinha"
                style={{ maxWidth: "58.8px", maxHeight: "7.79px" }}
              />
            </div>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Sidebar;
