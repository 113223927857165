import React from "react";
import "./_menuModal.scss";
import { Modal, ModalBody } from "reactstrap";
import PropTypes from "prop-types";

const MenuModal = ({
  isOpen,
  mainItems,
  secondaryItems,
  gourmetItems,
  onClose,
  categoryName,
  categoryImage,
}) => {
  const handleCloseClick = () => {
    onClose();
  };

  let allItens = [];

  if (mainItems) {
    allItens = [...allItens, ...mainItems];
  }
  if (secondaryItems) {
    allItens = [...allItens, ...secondaryItems];
  }
  if (gourmetItems != null) {
    allItens = [...allItens, ...gourmetItems.allGourmetItems];
  }

  // function ImageSize() {
  //   if (mainItems.length === 3) {
  //     return (
  //       <picture
  //         className="picture"
  //         style={{
  //           width: "25%",
  //           paddingTop: "1rem",
  //         }}
  //       >
  //         <source type="image/webp" srcSet={categoryImage.webp} />
  //         <img src={categoryImage.image} alt={categoryImage.title} />
  //       </picture>
  //     );
  //   }
  //   if (secondaryItems.length === 2) {
  //     return (
  //       <picture
  //         className="picture"
  //         style={{
  //           width: "30%",
  //           paddingTop: "1rem",
  //         }}
  //       >
  //         <source type="image/webp" srcSet={categoryImage.webp} />
  //         <img src={categoryImage.image} alt={categoryImage.title} />
  //       </picture>
  //     );
  //   }
  //   return (
  //     <picture
  //       className="picture"
  //       style={{
  //         width: "35%",
  //         paddingTop: "1rem",
  //       }}
  //     >
  //       <source type="image/webp" srcSet={categoryImage.webp} />
  //       <img src={categoryImage.image} alt={categoryImage.title} />
  //     </picture>
  //   );
  // }

  return (
    <Modal id="menu-modal" isOpen={isOpen} toggle={handleCloseClick}>
      <ModalBody
        style={{ maxHeight: "calc(100vh - 110px)", overflowY: "auto" }}
      >
        <section className="modal-menu-category">
          <div
            className="category-title"
            style={
              categoryName === "TORTA DE MACAXEIRA" ? { fontSize: "60px" } : {}
            }
          >
            {categoryName}
          </div>
          <div className="category-image">
            <img src={categoryImage.image} alt={categoryImage.title} />
          </div>
          {gourmetItems && (
            <span className="title gourmet-title">
              {gourmetItems.gourmetTitle}
            </span>
          )}
          {allItens.map((item) => {
            return (
              <>
                <div className="item" key={item.itemTitle}>
                  <span className="title">{item.itemTitle}</span>
                  <span className="prices">{item.itemPrices}</span>
                  <span className="description">{item.itemDescription}</span>
                  <span className="title">{item.itemGourmetTitle}</span>
                  <span className="prices">{item.itemGourmetPrices}</span>
                  {item.itemsGourmet &&
                    item.itemsGourmet.map((itemGourmet) => (
                      <>
                        <span className="prices">
                          {itemGourmet.itemGourmetName}
                        </span>
                        <span
                          className="description"
                          style={{ marginBottom: "12px" }}
                        >
                          {itemGourmet.itemGourmetDescription}
                        </span>
                      </>
                    ))}
                </div>
              </>
            );
          })}
        </section>
      </ModalBody>
    </Modal>
  );
};

MenuModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  mainItems: PropTypes.arrayOf(
    PropTypes.shape({
      itemDescription: PropTypes.string,
      itemPrices: PropTypes.string,
      itemTitle: PropTypes.string,
    })
  ),
  // eslint-disable-next-line react/require-default-props
  secondaryItems: PropTypes.arrayOf(
    PropTypes.shape({
      itemDescription: PropTypes.string,
      itemPrices: PropTypes.string,
      itemTitle: PropTypes.string,
    })
  ),
  // eslint-disable-next-line react/require-default-props
  gourmetItems: PropTypes.shape({
    gourmetTitle: PropTypes.string,
    allGourmetItems: PropTypes.arrayOf(
      PropTypes.shape({
        itemGourmetTitle: PropTypes.string,
        itemGourmetPrices: PropTypes.string,
        itemsGourmet: PropTypes.arrayOf(
          PropTypes.shape({
            itemGourmetName: PropTypes.string,
            itemGourmetDescription: PropTypes.string,
          })
        ),
      })
    ),
  }),
  categoryName: PropTypes.string.isRequired,
  categoryImage: PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    webp: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MenuModal;
