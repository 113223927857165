import React, { useState } from "react";
import icon from "../../assets/Icons/footer/delivery2.png";
import Deliver from "../../assets/Icons/footer/delivery.png";
import "./_footer.scss";
import footerData from "./data";

const Footer = () => {
  const [deliveryContacts, setDeliveryContacts] = useState(false);

  const handleClick = () => {
    setDeliveryContacts(!deliveryContacts);
  };

  return (
    <div className="footer-container">
      <div className="waves" />
      <div className="footer-items">
        <a
          href={footerData.links.whatsapp}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div id="whatsapp" />
        </a>
        <a
          href={footerData.links.instagram}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div id="instagram" />
        </a>
        <a
          href={footerData.links.facebook}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div id="facebook" />
        </a>

        <div
          id="contact-buttom"
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        >
          <div className="contact-number">
            <span className="button-name">Peça Online</span>
            <img className="delivery" src={icon} alt="" />
          </div>

          {deliveryContacts && (
            <div className="delivery-contacts">
              <div className="contacts-header">
                <h5>{footerData.contactsData.title}</h5>
                <a href={footerData.contactsData.deliveryLink}>Açai Aju Atalaia</a>
                <a href={footerData.contactsData.deliveryLink2}>Açaí Aju Grageru</a>
              </div>
              {/* <div className="contacts-content">
                {footerData.contactsData.phoneNumbers.map(
                  ({ linkNumber, number, schedule }, i) => (
                    <div
                      key={linkNumber + number + schedule + i.toString()}
                      className="contact-number"
                    >
                      <a href={`tel:+550${linkNumber}`}>{number}</a> 
                       <div className="schedule">{schedule}</div> 
                    </div>
                  )
                )}
              </div> */}
            </div>
          )}
        </div>

        <div id="div-bar" />
      </div>
    </div>
  );
};

export default Footer;
