import React from "react";
import "./_contacts.scss";
import Image from "../../assets/images/contact.png";
import Webp from "../../assets/images/contact.webp";
import Wave from "../../assets/images/wave.svg";

const Contacts = () => {
  return (
    <div id="contact">
      <div className="contact-screen">
        <div className="left">
          <div className="contacts-container">
          <div className="field">
            <h4> Contatos:</h4></div>
          <div className="contact-item">
            <span className="field"> Loja Atalaia 1: </span>{" "}
            <span className="contact-number">(79) 99819-0758 - 09h - 15h</span><br/>
          </div>
          <div className="contact-item">
            <span className="field">Loja Atalaia 2: </span>{" "}
            <span className="contact-number">(79) 3512-1313 - 15h - 0h</span><br/>
          </div>
          <div className="contact-item">
            <span className="field">Loja São José: </span>{" "}
            <span className="contact-number">(79) 99636-6549 - 15h - 0h</span>
          </div>
          </div>
        </div>
        <picture>
          <source type="image/webp" srcSet={Image} className="acai" />
          <img src={Webp} alt="acai" className="acai" />
        </picture>
        <img src={Wave} alt="purple-wave" className="purple-wave" />
      </div>
    </div>
  );
};

export default Contacts;
