// First Store
import FS_PHOTO_1 from "../../assets/images/stores/24h/24h-1.png";
import FS_PHOTO_1_WEBP from "../../assets/images/stores/24h/24h-1.webp";
import FS_PHOTO_2 from "../../assets/images/stores/24h/24h-2.png";
import FS_PHOTO_2_WEBP from "../../assets/images/stores/24h/24h-2.webp";

// Second Store
import SS_PHOTO_1 from "../../assets/images/stores/autoServico/Auto_Serviço-1.png";
import SS_PHOTO_2 from "../../assets/images/stores/autoServico/Auto-Serviço-2.png";
import SS_PHOTO_3 from "../../assets/images/stores/autoServico/Auto-Serviço-3.png";
import SS_PHOTO_1_WEBP from "../../assets/images/stores/autoServico/Auto_Serviço-1.webp";
import SS_PHOTO_2_WEBP from "../../assets/images/stores/autoServico/Auto-Serviço-2.webp";
import SS_PHOTO_3_WEBP from "../../assets/images/stores/autoServico/Auto-Serviço-3.webp";

// Third Store
import TS_PHOTO_1 from "../../assets/images/stores/gourmet/Gourmet-1.png";
import TS_PHOTO_1_WEBP from "../../assets/images/stores/gourmet/Gourmet-1.webp";

// Fourth Store
import FR_PHOTO_1 from "../../assets/images/stores/saoJose/São-José-1.png";
import FR_PHOTO_1_WEBP from "../../assets/images/stores/saoJose/São-José-1.webp";

// Fifth Store
import FF_PHOTO_1 from "../../assets/images/stores/distribuidora/Distribuidora-1.png";
import FF_PHOTO_2 from "../../assets/images/stores/distribuidora/Distribuidora-2.png";
import FF_PHOTO_3 from "../../assets/images/stores/distribuidora/Distribuidora-3.png";
import FF_PHOTO_1_WEBP from "../../assets/images/stores/distribuidora/Distribuidora-1.webp";
import FF_PHOTO_2_WEBP from "../../assets/images/stores/distribuidora/Distribuidora-2.webp";
import FF_PHOTO_3_WEBP from "../../assets/images/stores/distribuidora/Distribuidora-3.webp";

// Sixth Store
import CI_PHOTO_1 from "../../assets/images/stores/circus/Circus-1.png";
import CI_PHOTO_1_WEBP from "../../assets/images/stores/circus/Circus-1.webp";

// Seventh Store
import SN_PHOTO_1 from "../../assets/images/stores/carrara/Carrara-1.png";
import SN_PHOTO_1_WEBP from "../../assets/images/stores/carrara/Carrara-1.webp";

const storesData = [
  {
    storeName: "AÇAÍ AJU 24H",
    storeImages: [
      {
        imageTitle: "AÇAÍ AJU 24H - foto 1",
        image: FS_PHOTO_1,
        webp: FS_PHOTO_1_WEBP,
      },
      {
        imageTitle: "AÇAÍ AJU 24H - foto 2",
        image: FS_PHOTO_2,
        webp: FS_PHOTO_2_WEBP,
      },
    ],
    storeAdress: "Av. Santos Dumont, 198 - Atalaia, Aracaju - SE, 49037-475",
    openingHours: "Todos os dias apartir das 9:00",
    contactNumber: "(79) 99819-0758 (delivery das 09h às 15h)",
    googleMapAdress:
      "https://www.google.com/maps/place/A%C3%A7a%C3%AD+Aju+24h/@-10.9901521,-37.050016,15z/data=!4m5!3m4!1s0x0:0xd8198872397e8d5!8m2!3d-10.9901521!4d-37.050016",
  },
  {
    storeName: "AUTOSSERVIÇO ORLA",
    storeImages: [
      {
        imageTitle: "AUTOSSERVIÇO ORLA - foto 1",
        image: SS_PHOTO_1,
        webp: SS_PHOTO_1_WEBP,
      },
      {
        imageTitle: "AUTOSSERVIÇO ORLA - foto 2",
        image: SS_PHOTO_2,
        webp: SS_PHOTO_2_WEBP,
      },
      {
        imageTitle: "AUTOSSERVIÇO ORLA - foto 3",
        image: SS_PHOTO_3,
        webp: SS_PHOTO_3_WEBP,
      },
    ],
    storeAdress: "Av. Santos Dumont, 198 - Atalaia, Aracaju - SE, 49037-475",
    openingHours: "Todos os dias apartir das 9:00",
    contactNumber: "(79) 3243-6402",
    googleMapAdress: "https://goo.gl/maps/AfkHpAcYcuBsHRbu9",
  },
  {
    storeName: "AÇAÍ AJU GOURMET",
    storeImages: [
      {
        imageTitle: "AÇAÍ AJU GOURMET - foto 1",
        image: TS_PHOTO_1,
        webp: TS_PHOTO_1_WEBP,
      },
    ],
    storeAdress:
      "Avenida Santos Dumont, 1620 - Coroa do meio, Aracaju - SE, 49035-730",
    openingHours: "15h às 0h (Fecha às terças-feiras)⁣⁣⁣⁣⁣⁣",
    contactNumber: "(79) 3243-1433 / (79) 99636-6549",
    googleMapAdress: "https://goo.gl/maps/rWbYQNt2M27GKgPG8",
  },
  {
    storeName: "AÇAÍ AJU SÃO JOSÉ",
    storeImages: [
      {
        imageTitle: "AÇAÍ AJU SÃO JOSÉ - foto 1",
        image: FR_PHOTO_1,
        webp: FR_PHOTO_1_WEBP,
      },
    ],
    storeAdress:
      "Praça Assis Chateaubriand, 608 - Salgado Filho, Aracaju - SE, 49020-360",
    openingHours: "12h às 0h",
    contactNumber: "(79) 99636-6549 / (79) 3512-1313 (delivery das 15h à 0h)",
    googleMapAdress: "https://goo.gl/maps/qgmuDwsEWpYoiyeFA",
  },
  {
    storeName: "DISTRIBUIDORA AÇAÍ AJU",
    storeImages: [
      {
        imageTitle: "DISTRIBUIDORA AÇAÍ AJU - foto 1",
        image: FF_PHOTO_1,
        webp: FF_PHOTO_1_WEBP,
      },
      {
        imageTitle: "DISTRIBUIDORA AÇAÍ AJU - foto 2",
        image: FF_PHOTO_2,
        webp: FF_PHOTO_2_WEBP,
      },
      {
        imageTitle: "DISTRIBUIDORA AÇAÍ AJU - foto 3",
        image: FF_PHOTO_3,
        webp: FF_PHOTO_3_WEBP,
      },
    ],
    storeAdress: "Av. Melício Machado, 951 - Atalaia, Aracaju - SE, 49037-440",
    openingHours: "9h às 22h",
    contactNumber: "(79) 99670-9194",
    googleMapAdress: "https://goo.gl/maps/QQKRzvzpuJdDLd7B6",
  },
  {
    storeName: "AÇAÍ AJU CIRCUS",
    storeImages: [
      {
        imageTitle: "AÇAÍ AJU CIRCUS - foto 1",
        image: CI_PHOTO_1,
        webp: CI_PHOTO_1_WEBP,
      },
    ],
    storeAdress:
      "Rua Orlando Magalhães Maia, Praça Alda Teixeira, 1171 - Jardins, Aracaju - SE, 49025-530",
    openingHours:
      "17h às 23h⁣ (Terça à Quinta)⁣⁣⁣⁣⁣⁣ / 17h às 0h⁣ (Sextas e Sábados)⁣⁣⁣⁣⁣⁣",
    contactNumber: null,
    googleMapAdress: "https://g.page/circus-food-park-se?share",
  },
  // {
  //   storeName: "AÇAÍ AJU CARRARA",
  //   storeImages: [
  //     {
  //       imageTitle: "AÇAÍ AJU CARRARA - foto 1",
  //       image: SN_PHOTO_1,
  //       webp: SN_PHOTO_1_WEBP,
  //     },
  //   ],
  //   storeAdress: "Rua Niceu Dantas, 394 - Atalaia, Aracaju - SE, 49037-470",
  //   openingHours: "17h às 23h (Domingo a Quinta)",
  //   contactNumber: "(79) 99839-6568",
  //   googleMapAdress: "https://goo.gl/maps/r6UurweajkXa9T5cA",
  // },
];

export default storesData;
