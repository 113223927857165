import React from "react";

import "./_layout.scss";

// Components
import Grid from "./Grid/Grid";
import Content from "../content/Content";
import Sidebar from "../sidebar/Sidebar";
import Footer from "../footer/Footer";

const Layout = () => {
  return (
    <Grid>
      <Sidebar />
      <Content />
      <Footer />
    </Grid>
  );
};

export default Layout;
