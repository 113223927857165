import React, { useState } from "react";

import { Col, Row } from "reactstrap";
import StoresNavBar from "./storesNavBar/StoresNavBar";
import StoresCarousel from "./storesCarousel/StoresCarousel";
import AdressModal from "../../components/adressModal/AdressModal";

import "./_stores.scss";

import linkImg from "../../assets/Icons/stores/link-img.png";

import storesData from "./data";

const Stores = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImages, setCurrentImages] = useState(storesData[0].storeImages);
  const [storeInformation, setStoreInformation] = useState(storesData[0]);
  const [selectedStore, setSelectedStore] = useState(storesData[0].storeName);

  const handleModalOpen = (event) => {
    event.preventDefault();
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleStoreClick = (choosedStore) => {
    const foundStore = storesData.find(({ storeName }) => {
      return storeName === choosedStore;
    });

    setCurrentImages(foundStore.storeImages);
    setStoreInformation(foundStore);
    setSelectedStore(foundStore.storeName);
  };

  return (
    <>
      <div id="stores-page">
        <Row>
          <Col sm="12" md="5">
            <StoresNavBar
              data={storesData}
              handleStoreClick={handleStoreClick}
              selectedStore={selectedStore}
            />
          </Col>
          <Col sm="12" md="7">
            <StoresCarousel storeImages={currentImages} />

            <a
              href="google.com.br"
              onClick={handleModalOpen}
              className="modal-btn"
            >
              <div className="modal-btn-display">
                <div>
                  <img src={linkImg} alt="" />
                </div>
                <div className="btn-text-container">
                  <span>ENDEREÇO</span>
                </div>
              </div>
            </a>
          </Col>
        </Row>
      </div>
      {isModalOpen && (
        <AdressModal
          isOpen={isModalOpen}
          onClose={handleModalClose}
          data={storeInformation}
        />
      )}
    </>
  );
};

export default Stores;
