/* Assets */

// Images
import logoImg from "../../assets/images/sidebar/logo.png";
import logoText from "../../assets/images/sidebar/logo-text.png";
import littleWave from "../../assets/images/sidebar/little-wave.svg";

// Icons
import storesIcon from "../../assets/Icons/sidebar/stores-icon.png";
import menuIcon from "../../assets/Icons/sidebar/menu-icon.png";
import reservationsIcon from "../../assets/Icons/sidebar/contact.png";
import aboutIcon from "../../assets/Icons/sidebar/about-icon.png";
import arrowIcon from "../../assets/Icons/sidebar/arrow-icon.png";

const sideBarData = {
  logoData: {
    logoImg,
    logoText,
  },
  menuData: {
    littleWave,
    arrowIcon,
    linkData: [
      {
        id: 1,
        link: "/stores",
        Icon: storesIcon,
        imgAlt: "Nossas Lojas",
        text: "Nossas Lojas",
      },
      {
        id: 2,
        link: "/menu",
        Icon: menuIcon,
        imgAlt: "Cardápio",
        text: "Cardápio",
      },
      {
        id: 3,
        link: "/reservations",
        Icon: reservationsIcon,
        imgAlt: "Reservas",
        text: "Contatos",
      },
      {
        id: 4,
        link: "/about",
        Icon: aboutIcon,
        imgAlt: "Sobre o açai aju",
        text: "Sobre o açai aju",
      },
    ],
  },
};

export default sideBarData;
